<template>
  <section style="transition: all .5s ease;">
    <div class="modal d-flex justify-content-end">
      <div class="bg-white w-50">
        <div class="d-flex align-items-center p-2 border-b">
          <strong class="my-0 text-dark text-10">Lengkapi Informasi Assignment</strong>
          <span class="ml-auto k-close-circle cursor-pointer text-10" @click="$emit('close')"></span>
        </div>
        <b-overlay
          variant="light"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <validation-observer ref="formRules"
            v-slot="{ invalid }">
            <b-row class="p-2">
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Talent
                  </template>
                  <v-select
                    v-model="talent"
                    label="full_name"
                    :reduce="option => option"
                    :options="listTalent"
                    disabled
                    placeholder="Ketik untuk mencari..."
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Partner
                  </template>
                  <v-select
                    v-model="partner"
                    label="name"
                    :reduce="option => option"
                    :options="listPartner"
                    placeholder="Ketik untuk mencari..."
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Leader
                  </template>
                  <v-select
                    v-model="leader"
                    label="name"
                    :reduce="option => option.id"
                    :options="listLeader"
                    placeholder="Ketik untuk mencari..."
                    @search="(search) => search.length > 0 ? onSearchLeader(search) : ''"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Device
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Device"
                    rules="required"
                  >
                    <v-select
                      v-model="device"
                      label="device_name"
                      :reduce="option => option.id"
                      :options="listDevice"
                      placeholder="Ketik untuk mencari..."
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Kantor
                  </template>
                  <v-select
                    v-model="office"
                    label="working_space_name"
                    :reduce="option => option.id"
                    :options="listOffice"
                    placeholder="Ketik untuk mencari..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-5 p-2">
              <button
                class="button-primary w-[200px] py-[8px]"
                :disabled="invalid || (!Number.isInteger(device) && !Number.isInteger(office) && !Number.isInteger(leader))"
                @click.prevent="submit()"
              >
                Simpan
              </button>
            </div>
          </validation-observer>
        </b-overlay>
      </div>
    </div>
    <div class="modal-backdrop"></div>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    talentAssignments: Object,
    deviceExist: Array,
    idSelected: Number,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      alertError,
      alertSuccess,

      listTalent: [],
      listPartner: [],
      listLeader: [],
      listDevice: [],
      listOffice: [],

      talent: null,
      partner: null,
      leader: null,
      device: null,
      office: null,
    }
  },
  beforeMount() {
    this.getListDevice()
    this.getListOffice()
    this.loadData()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        device_id: Number.isInteger(this.device) ? this.device : this.talentAssignments.device_id,
        office_id: Number.isInteger(this.office) ? this.office : this.talentAssignments.office_id,
        staff_id: Number.isInteger(this.leader) ? this.leader : this.talentAssignments.staff_id,
      }

      const url = `v1/partner_assignments/talent/${this.idSelected}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$emit('close')
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async getListDevice() {
      this.loading = true
      this.offset = 0
      const params = `offset=0&limit=20&holder_name=IT Support&partner_id=${this.talentAssignments.no_partner}`
      const url = `v1/devices?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          if (data) this.listDevice = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListOffice() {
      this.loading = true
      this.offset = 0
      const params = 'status=true'
      const url = `v1/working_spaces/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          if (data) this.listOffice = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListLeader(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&position_name=Team Lead Non Advertiser,Team Lead Advertiser&keyword=${keyword}`
      const url = `v1/staffs?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listLeader = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchLeader: _.debounce((search, it) => {
      it.getListLeader(search)
    }, 500),
    onSearchLeader(search) {
      this.searchLeader(search, this)
    },
    loadData() {
      this.talent = this.talentAssignments.talent_name
      this.partner = this.talentAssignments.partner_name
      this.leader = this.talentAssignments.staff_name
      this.device = this.talentAssignments.device
      this.office = this.talentAssignments.office_name
    },
  },
}
</script>
